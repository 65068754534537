import { sharedContentStyles } from '../overlayView/overlayViewStyles'

const styles = {
  subHeader: {
    ...sharedContentStyles.subHeader,
    textAlign: 'center',
  },
  para: {
    color: '#5a5a5a',
    fontSize: '20px',
    marginTop: '20px',
    textAlign: 'center',
    lineHeight: '26px',
    marginBottom: '25px',
  },
  cabMeterSVG: {
    width: '150px',
    margin: 'auto',
    height: '155px',
    '& svg': {
      width: '150px',
      margin: 'auto',
      height: '175px',
    },
  },
  button: {
    color: '#0500ee',
    border: '2px solid #0500ee',
    display: 'block',
    padding: '12px 26px',
    fontSize: '18px',
    marginTop: '20px',
    borderRadius: '26px',
    margin: 'auto',
    width: '199px',
    textAlign: 'center',
    marginTop: '20px',
    marginBottom: '50px',
  },
  subTextSpan: {
    display: 'block',
    fontSize: '16px',
    color: '#9d9d9d',
    lineHeight: '30px',
  },
  estimatedCostNotice: {
    color: '#1f51e0',
    display: 'block',
    fontSize: '44px',
    textAlign: 'center',
    paddingTop: '10px',
  },
  subtitle: {
    display: 'block',
    textAlign: 'center',
    paddingTop: '5px',
    color: '#1f51e0',
    fontSize: '15px',
    fontWeight: 800,
    textTransform: 'uppercase',
  },
}

export default styles
