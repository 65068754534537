import warningIcon from '../../assets/images/t-warning.svg'

const styles = {
  subHeader: {
    color: '#e41818',
    fontSize: '18px',
    fontWeight: 500,
    backgroundImage: `url(${warningIcon})`,
    backgroundPosition: 'left center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '20px',
    paddingLeft: '30px',
  },
  para: {
    color: '#5a5a5a',
    fontSize: '14px',
    marginTop: '7px',
    lineHeight: '20px',
  },
  closeLink: {
    color: '#e41818',
    padding: '13px 39px',
    display: 'inline-block',
    fontSize: '16px',
    marginTop: '27px',
    fontWeight: 500,
    borderRadius: '25px',
    textAlign: 'center',
    border: '1px solid #e41818',
  },
}

export default styles
