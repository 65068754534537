const style = {
  noticeBox: {
    position: 'relative',
    zIndex: 100,
    margin: '20px',
  },
  title: {
    color: '#fff',
    fontSize: '18px',
    fontWeight: 600,
  },
  para: {
    color: '#d3dcfa',
    fontSize: '16px',
    fontWeight: 400,
    marginTop: '5px',
    lineHeight: '20px',
  },
  retryBtn: {
    color: '#fff',
    border: '1px solid #fff',
    display: 'inline-block',
    borderRadius: '30px',
    padding: '10px 25px',
    marginTop: '25px',
  },
}

export default style
