module.exports = {
  ADYEN_ORIGIN_KEY: process.env.ADYEN_ORIGIN_KEY,
  ADYEN_ENVIRONMENT: process.env.ADYEN_ENVIRONMENT,
  ADYEN_MERCHANT_IDENTIFIER: process.env.ADYEN_MERCHANT_IDENTIFIER,
  ADYEN_URL_PREFIX: process.env.ADYEN_URL_PREFIX,
  ADYEN_MERCHANT_ACCOUNT: process.env.ADYEN_MERCHANT_ACCOUNT,
  APPLE_MERCHANT_ID: process.env.APPLE_MERCHANT_ID,
  APPLE_MERCHANT_DOMAIN: process.env.APPLE_MERCHANT_DOMAIN,
  ADYEN_ENVIRONMENT: process.env.ADYEN_ENVIRONMENT,
  GOOGLEPAY_MERCHANT_IDENTIFIER: process.env.GOOGLEPAY_MERCHANT_IDENTIFIER,
}
