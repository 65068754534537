import React from 'react'
import injectSheet from 'react-jss'
import styles from './overlayViewStyles'
import { CSSTransition } from 'react-transition-group'

class OverlayViewEl extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openInner: false,
    }
    this.close = this.close.bind(this)
  }
  close(e) {
    e.preventDefault()
    if (!e.target.id.includes('close-on-click')) return false
    this.setState(
      {
        openInner: false,
      },
      () => {
        setTimeout(() => {
          this.props.openCloseOverlay(false, e)
        }, 10)
      }
    )
  }

  componentWillReceiveProps(newProps) {
    if (newProps.overlayOpen && !this.state.openInner) {
      setTimeout(() => {
        this.setState({
          openInner: true,
        })
      }, 100)
    }
  }

  render() {
    const { background, panel } = this.props.classes
    return (
      <CSSTransition
        in={this.props.overlayOpen}
        timeout={200}
        classNames="fade-in"
        unmountOnExit
      >
        <div
          id="close-on-click-1"
          className={background}
          onClick={e => this.close(e)}
        >
          <CSSTransition
            in={this.state.openInner}
            timeout={200}
            classNames="slide-up"
            unmountOnExit
          >
            <div className={panel} id="panel">
              {this.props.children}
            </div>
          </CSSTransition>
        </div>
      </CSSTransition>
    )
  }
}

const OverlayView = injectSheet(styles)(OverlayViewEl)
export default OverlayView
