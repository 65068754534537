import formStyles from '../../assets/sharedJSS/formStyles'

const styles = () => {
  const style = {
    padding: {
      padding: '20px',
    },
    paymentFormView: {
      padding: '20px',
      paddingTop: '0px',
      position: 'relative',
      marginTop: '40px',
      zIndex: 10,
      transition: 'all 0.4s ease-in-out',
      marginBottom: '50px',
      overflow: 'hidden',
      left: 0,
      right: 0,
    },
    paymentHeader: {
      transition: 'all 0.3s ease-in-out',
    },
    opacityDown: {
      opacity: 0.4,
    },
    paymentBoxBoxWrapper: {
      marginLeft: '-20px',
      marginRight: '-20px',
      overflow: 'hidden',
    },
    paymentBoxWrapper: {
      padding: '20px!important',
      backgroundColor: ' #fff',
      marginTop: '20px',
      marginLeft: '10px',
      marginRight: '10px',
      borderRadius: '15px',
      position: 'relative',
      transition: 'all 0.3s ease-in-out',
    },
    boxPositionShift: {
      marginLeft: '400px',
      marginRight: '-400px',
      '-webkit-appearance': 'none',
      opacity: 0,
    },
    subHeader: {
      color: '#8ac6f2',
      fontSize: '18px',
      marginTop: '10px',
    },
    mainTitle: {
      fontSize: '32px',
      color: ' #fff',
      fontWeight: 700,
      margin: 0,
      paddingTop: '20px',
      lineHeight: '36px',
    },
    headerPara: {
      color: '#fff',
      fontSize: '18px',
      marginTop: '13px',
      lineHeight: '30px',
      fontWeight: 600,
      transition: 'all 0.3s ease-in-out',
      '& strong': {
        fontWeight: 700,
      },
    },
    para: {
      color: '#94c0ff',
      fontSize: '17px',
      marginTop: '12px',
      lineHeight: '26px',
    },
    loadingOverlay: {
      zIndex: 10,
      position: 'absolute',
      // marginLeft: '15px',
      transition: 'all 0.3s ease-out',
      // transform: 'translate(-400px)',
    },
    spinnerIn: {
      transform: 'translate(0)',
    },
    loadingMsgMain: {
      color: '#fff',
      display: 'block',
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '27px',
    },
    loadingMsgSub: {
      display: 'block',
      color: '#8ac6f2',
      lineHeight: '24px',
      fontSize: '16px',
    },
    paymentHeaderIn: {},
    paymentHeaderOut: {
      opacity: 0,
    },
    completeNotice: {
      padding: '20px',
      position: 'absolute',
      transition: 'all 0.3s ease-in-out',
      zIndex: 10,
    },
    completeTitle: {
      color: '#1e50cf',
      margin: 0,
      fontSize: '23px',
      fontWeight: 600,
      display: 'block',
      marginLeft: '40px',
    },
    completedPara: {
      fontSize: '14px',
      display: 'block',
      color: '#1c51cf',
      marginLeft: '40px',
      marginTop: '6px',
      lineHeight: '20px',
    },
    tickWrapper: {},

    journeyComplete: {
      position: 'absolute',
      zIndex: 10,
      padding: '20px',
      transition: 'all 0.3s ease-in-out',
    },

    genericErrorBox: {
      position: 'relative',
      zIndex: 10,
    },
    marginTop: {
      marginTop: '20px',
    },
  }

  return style
}

export default styles
