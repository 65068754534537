import React from 'react'
import NumberEntryViewStyles from './numberEntryViewStyles'
import injectSheet from 'react-jss'
import { generateJourneysViewLink } from '../network/mutations/generateJourneysViewLink'
import { Mutation } from 'react-apollo'
import { get } from 'lodash'
class NumberEntryView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      input: '',
      isComplete: false,
      errorMsg: null,
    }
    this.setValue = this.setValue.bind(this)
    this.submit = this.submit.bind(this)
    this.completeReq = this.completeReq.bind(this)
    this.networkError = this.networkError.bind(this)
  }

  setValue(e) {
    this.setState({
      input: e.target.value,
    })
  }

  completeReq(res) {
    if (res.generateJourneysViewLink) {
      this.setState({
        isComplete: true,
      })
    }
  }

  showError(errorMsg) {
    this.setState({
      errorMsg,
    })
    setTimeout(() => {
      this.setState({
        errorMsg: null,
      })
    }, 4000)
  }

  networkError(res) {
    const serverErrors = get(res, 'graphQLErrors', [])
    let errorMsg
    errorMsg = serverErrors.length
      ? serverErrors[0].message
      : 'Sorry, problem finding your records'
    this.showError(errorMsg)
  }

  submit(e, generateJourneysViewLink) {
    e.preventDefault()
    const { input: phone } = this.state
    if (!phone || !phone.length) {
      this.showError('Please enter numbers only')
      return
    }
    if (isNaN(phone)) {
      this.showError('Numbers only')
      return
    }
    generateJourneysViewLink({
      variables: {
        phone,
      },
    })
  }
  render() {
    const {
      inputBoxWrapper,
      title,
      para,
      viewWrapper,
      inputBox,
      link,
      label,
      inlineLoading,
      paraBold,
      errorMsgSpan,
    } = this.props.classes
    const { input, isComplete, errorMsg } = this.state
    return (
      <div className={viewWrapper}>
        <h3 className={title}>Statements</h3>
        <div className={inputBoxWrapper}>
          {isComplete ? (
            <>
              <p className={paraBold}>We have sent an SMS to {input}</p>
              <p className={para}>
                Please follow the link provided in the message to view your
                previous statements.
              </p>
            </>
          ) : (
            <Mutation
              mutation={generateJourneysViewLink}
              onCompleted={this.completeReq}
              onError={this.networkError}
            >
              {(generateJourneysViewLink, client) => {
                if (client.loading) {
                  return (
                    <span className={inlineLoading}>
                      <div className={'lds-ellipsis dark'}>
                        <div />
                        <div />
                        <div />
                        <div />
                      </div>
                    </span>
                  )
                }
                return (
                  <>
                    <p className={para}>
                      Please enter your mobile number below to view your past
                      statements with Airpay. You will be sent a secure link via
                      SMS to access your statements.
                    </p>
                    <label className={label}>
                      Your phone number
                      {errorMsg && (
                        <span className={errorMsgSpan}>{errorMsg}</span>
                      )}
                    </label>
                    <input
                      type="tel"
                      value={input}
                      className={inputBox}
                      onChange={this.setValue}
                    />
                    <a
                      href="#_"
                      className={link}
                      onClick={e => this.submit(e, generateJourneysViewLink)}
                    >
                      Send Secure Link
                    </a>
                  </>
                )
              }}
            </Mutation>
          )}
        </div>
      </div>
    )
  }
}

export default injectSheet(NumberEntryViewStyles)(NumberEntryView)
