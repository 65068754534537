const styles = {
  background: {
    backgroundColor: 'rgba(0,0,0,0.75)',
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 100,
    transition: 'all 0.25s ease-in-out',
  },
  panel: {
    left: 0,
    right: 0,
    bottom: '-100%',
    padding: '20px',
    position: 'absolute',
    minHeight: '400px',
    backgroundColor: '#fff',
    borderRadius: '15px 15px 0px 0px',
    transition: 'all 0.25s ease-in-out',
  },
}

const sharedContentStyles = {
  panel: {
    width: '85vw',
    right: '-100vw',
    top: 0,
    bottom: 0,
    padding: '20px',
    position: 'absolute',
    backgroundColor: '#fff',
    borderRadius: '15px 0px 0px 0',
    transition: 'all 0.25s ease-in-out',
  },
  para: {
    color: '#5a5a5a',
    fontSize: '14px',
    marginTop: '7px',
    lineHeight: '20px',
  },
  subHeader: {
    color: '#1f51e0',
    fontSize: '18px',
    fontWeight: 600,
  },
  actionBar: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  backLink: {
    color: '#1f51e0',
    display: 'block',
    padding: '15px 0px',
    fontSize: '17px',
    borderTop: '1px solid #eaeaea',
    fontWeight: 500,
    position: 'relative',
    paddingLeft: '50px',
  },
  linkIcon: {
    position: 'absolute',
    left: '10px',
    top: '14px',
    '& svg': {
      height: '18px',
    },
  },
  content: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    padding: '20px',
    bottom: '48px',
    overflow: 'scroll',
  },
  paraHeader: {
    color: '#2a5ce1',
    paddingTop: '22px',
    lineHeight: '20px',
    fontWeight: 600,
  },

  bulletPoints: {
    extend: 'para',
    listStyleType: 'disc',
    paddingLeft: '18px',
    '& li': {
      paddingBottom: '10px',
      '& strong': {
        display: 'block',
        fontWeight: 600,
      },
    },
  },
}

export default styles
export { sharedContentStyles }
