import React from 'react'
import injectSheet from 'react-jss'
import styles from './errorViewStyles'

class ErrorViewEl extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { subHeader, para, closeLink } = this.props.classes
    const message =
      this.props.error && this.props.error.message
        ? this.props.error.message
        : 'Problem connecting to network'
    return (
      <>
        <h6 className={subHeader}>Error</h6>
        <p className={para}>{message}</p>
        <a
          id="close-on-click-3"
          href="#_"
          className={closeLink}
          onClick={e => this.props.close(e)}
        >
          OK
        </a>
      </>
    )
  }
}

const ErrorView = injectSheet(styles)(ErrorViewEl)
export default ErrorView
