const styles = {
  title: {
    fontSize: '24px',
    padding: '13px 20px',
    color: '#fff',
  },
  viewWrapper: {
    zIndex: 100,
    position: 'relative',
  },
  inputBoxWrapper: {
    margin: '0px 10px 10px 10px',
    borderRadius: '10px',
    padding: '13px',
    backgroundColor: '#fff',
  },
  para: {
    fontSize: '13px',
    lineHeight: '17px',
    color: '#3c3c3c',
  },
  paraBold: {
    fontSize: '13px',
    lineHeight: '17px',
    fontWeight: 600,
    color: '#3c3c3c',
    paddingBottom: '4px',
    paddingTop: '1px',
  },
  inputBox: {
    width: '100%',
    display: 'block',
    boxSizing: 'border-box',
    border: '1px solid #ccc',
    marginTop: '5px',
    fontSize: '22px',
    padding: '5px 9px',
    borderRadius: '4px',
  },
  link: {
    display: 'block',
    textAlign: 'center',
    marginTop: '15px',
    border: '2px solid #0500ee',
    padding: '12px',
    borderRadius: '35px',
    fontWeight: 500,
    marginBottom: '5px',
    color: '#0600ee',
  },
  label: {
    color: '#0500ee',
    fontSize: '12px',
    marginTop: '20px',
    display: 'block',
  },
  inlineLoading: {
    padding: '20px 0px',
    display: 'block',
    color: '#a9a9a9',
    fontStyle: 'italic',
  },
  errorMsgSpan: {
    float: 'right',
    color: 'red',
    fontWeight: 600,
  },
}

export default styles
