import React from 'react'
import injectSheet from 'react-jss'
import _ from 'lodash'
import styles from './transactionViewStyles'
import { Query } from 'react-apollo'
import moment from 'moment'
import { journeysFromCode } from '../network/queries/journeys-from-code'
import cx from 'classnames'

class TransactionsViewEl extends React.Component {
  constructor(props) {
    super(props)
    const journeysViewCode = _.get(
      this,
      'props.match.params.journeysViewCode',
      false
    )
    this.state = {
      journeysViewCode,
    }
    this.formatRightCol = this.formatRightCol.bind(this)
  }

  formatRightCol(transaction) {
    const { classes } = this.props
    const { cost, right, status } = classes
    const { paymentStatus, adyenLastSentForSettleAt } = transaction
    const showSettledTime = adyenLastSentForSettleAt && paymentStatus === 'paid'
    return (
      <div className={right}>
        <span className={cost}>
          {transaction.grandTotal
            ? `£${(Number(transaction.grandTotal) / 100).toFixed(2)}`
            : '--'}
        </span>
        <span className={cx(status, classes[paymentStatus])}>
          {paymentStatus.toUpperCase()}{' '}
          {showSettledTime &&
            `at ${moment(adyenLastSentForSettleAt).format(
              'HH:mm [on] DD/MM/YYYY'
            )}`}
        </span>
      </div>
    )
  }

  render() {
    const { journeysViewCode } = this.state
    const {
      listView,
      Ul,
      Li,
      date,
      cost,
      meta,
      left,
      right,
      viewWrapper,
      title,
      inlineLoading,
      jid,
      status,
    } = this.props.classes
    if (!journeysViewCode) {
      return <h1>No Link Specified</h1>
    }
    return (
      <div className={viewWrapper}>
        <h3 className={title}>Statement</h3>
        <div className={listView}>
          <Query query={journeysFromCode} variables={{ journeysViewCode }}>
            {({ loading, error, data }) => {
              if (loading) {
                return (
                  <span className={inlineLoading}>
                    <div className={'lds-ellipsis dark'}>
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>
                  </span>
                )
              }
              if (error) {
                const [err = { message: 'An unexpected error occurred.' }] =
                  error.graphQLErrors || []
                return <span className={inlineLoading}>{err.message}</span>
              }
              const { journeysFromCode = [] } = data || {}
              if (!journeysFromCode.length) {
                return <span className={inlineLoading}>No Journeys Found</span>
              }
              return (
                <ul className={Ul}>
                  {journeysFromCode.map(journey => {
                    const transaction = journey.latestTransaction
                    return (
                      <li className={Li} key={journey.id}>
                        <div className={left}>
                          <span className={meta}>
                            {journey.driver.fleet.name}
                          </span>
                          <span className={jid}>
                            Trip #{journey.icabbiTripId}
                          </span>
                          <span className={date}>
                            {transaction
                              ? moment(transaction.createdAt).format(
                                  'DD/MM/YYYY [at] HH:mm'
                                )
                              : 'Unknown'}
                          </span>
                        </div>
                        {this.formatRightCol(transaction)}
                      </li>
                    )
                  })}
                </ul>
              )
            }}
          </Query>
        </div>
      </div>
    )
  }
}

export default injectSheet(styles)(TransactionsViewEl)
