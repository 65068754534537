import React from 'react'
import injectSheet from 'react-jss'
import styler from './paymentViewStyles'
import cx from 'classnames'
import { Query } from 'react-apollo'
import { journeyById } from '../../network/queries/journeyById'
import { lastSeen } from '../../network/mutations/lastSeen'
import moment from 'moment'
import { CSSTransition } from 'react-transition-group'
import OverlayView from '../../components/overlayView/overlayView'
import ErrorView from '../../components/errorView/errorView'
import carSVG from '../../assets/images/car.svg'
import _ from 'lodash'
import ReactSVG from 'react-svg'
import NotFound from '../../notFound/notFound'
import client from '../../network/network'
import AdyenCheckoutForm from '../checkoutForm/adyenCheckoutForm'
import { Helmet } from 'react-helmet'

class PaymentViewEl extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      paymentData: {},
      error: false,
      showWelcomeMsg: true,
      isLoading: false,
      paymentComplete: false,
      animatedTick: false,
      overlayOpen: false,
      isComplete: false,
      isCompleteWithoutAction: false,
      adyenLoaded: false,
      paymentWaiting: false,
    }
    this.setAdyenLoaded = this.setAdyenLoaded.bind(this)

    this.handleLoading = this.handleLoading.bind(this)
    this.onCompleteCheckout = this.onCompleteCheckout.bind(this)
    this.openCloseOverlay = this.openCloseOverlay.bind(this)
    this.animateTick = this.animateTick.bind(this)
    this.closeWelcomeMsg = this.closeWelcomeMsg.bind(this)
    this.paymentSuccess = this.paymentSuccess.bind(this)
    this.paymentError = this.paymentError.bind(this)
    this.myRef = React.createRef()
  }

  componentDidMount() {
    client
      .mutate({
        mutation: lastSeen,
        variables: {
          firstSeenAt: moment.utc().format(),
          lastSeenAt: moment.utc().format(),
          journeyId: this.props.route.params.id,
        },
      })
      .then(data => {})
      .catch(err => {})
  }

  setCurrentPaymentState(props) {
    const paymentData = _.get(props, 'data.journeys[0]', {})
    const journeyStatus = _.get(props, 'data.journeys[0].status', null)
    const transactionCount = _.get(paymentData, 'transactions', []).length
    const transactions = _.get(paymentData, 'transactions[0]', [])
    const hidePaymentConfirmation = _.get(
      paymentData,
      'driver.fleet.enableSynchronousAuth',
      true
    ) // only show confirmation if the job has a driver and sync-auth is off

    const error =
      props.error && props.error.message ? props.error.message : null

    const isCompleteWithoutAction =
      journeyStatus === 'COMPLETED' && transactionCount === 0

    if (_.isEmpty(transactions)) {
      this.setState({
        paymentData: paymentData,
        paymentComplete: false,
        isComplete: false,
        isCompleteWithoutAction,
        overlayOpen: !_.isEmpty(error),
        error,
      })
    } else {
      const paymentComplete = transactions.status === 'AUTHORISED'
      const paymentWaiting =
        !hidePaymentConfirmation &&
        transactions.status === 'AWAITING_CAPTURE_NOTIFICATION'
      const isComplete =
        transactions.status === 'CAPTURED' ||
        (transactions.status === 'AWAITING_CAPTURE_NOTIFICATION' &&
          hidePaymentConfirmation)

      this.setState({
        paymentData: paymentData,
        paymentComplete: paymentComplete,
        paymentWaiting: paymentWaiting,
        isComplete: isComplete,
        isCompleteWithoutAction: false,
        overlayOpen: !_.isEmpty(error),
        error,
      })
    }
  }
  componentWillReceiveProps(newProps) {
    if (newProps.data !== this.props.data || newProps.error) {
      // Journey already received but theres a network error! (polling issue...)
      if (this.props.data && this.props.data.journeys && newProps.error) {
        this.paymentError({})
      } else {
        this.setCurrentPaymentState(newProps)
      }
    }
  }

  onCompleteCheckout(stripe) {}

  handleLoading(isLoading) {
    this.setState({
      isLoading: isLoading,
    })
  }

  animateTick(showTick) {
    setTimeout(() => {
      this.setState({
        animatedTick: showTick,
      })
    }, 200)
  }

  getPaymentCompleteMessage() {
    const { completeNotice, mainTitle, headerPara, para } = this.props.classes
    const { fixedCost } = this.props

    return (
      <div className={completeNotice}>
        <ReactSVG src={carSVG} />
        <h3 className={mainTitle}>Enjoy your journey!</h3>
        <p className={headerPara}>
          Thank you for using Airpay. You will be charged{' '}
          {fixedCost ? 'immediately.' : 'at the end of your journey.'}
        </p>
        <p className={para}>
          Check back here once your journey is complete to confirm your payment
          has been made.
        </p>
      </div>
    )
  }

  getPaymentWaitingMessage() {
    const { completeNotice, mainTitle, headerPara, para } = this.props.classes

    return (
      <div className={completeNotice}>
        <div className={cx('lds-ellipsis')}>
          <div />
          <div />
          <div />
          <div />
        </div>
        <p className={headerPara}>Confirming Payment...</p>
        <p className={para}>
          Please wait a few moments while we confirm your payment
        </p>
      </div>
    )
  }

  getLoadingMessage(mainString, isLoading, includePadding) {
    const {
      loader,
      loadingMsgMain,
      loadingMsgSub,
      loadingOverlay,
      spinnerIn,
      padding,
    } = this.props.classes

    const spinnerInClass = isLoading ? spinnerIn : {}
    const paddingClass = includePadding ? padding : {}
    return (
      <div className={cx(loadingOverlay, spinnerInClass, paddingClass)}>
        <div className={cx('lds-ellipsis', loader)}>
          <div />
          <div />
          <div />
          <div />
        </div>
        <span className={loadingMsgMain}>{mainString}</span>
        <span className={loadingMsgSub}>Please wait...</span>
      </div>
    )
  }

  getErrorView() {
    const {
      loadingMsgMain,
      loadingMsgSub,
      padding,
      genericErrorBox,
    } = this.props.classes

    const { paymentData, error } = this.state
    const gatewayStatus = _.get(
      paymentData,
      'transactions[0].gatewayStatus',
      null
    )
    const transactionError =
      gatewayStatus === 'ERROR' || gatewayStatus === 'FAILED'

    const cancelledError =
      _.get(paymentData, 'transactions[0].status', null) === 'CANCELLED' ||
      paymentData.status === 'CANCELLED'

    const journeyFare = paymentData.dispatcherTripCost
      ? `£${(paymentData.dispatcherTripCost / 100).toFixed(2)} for`
      : 'at the end of'

    const title = cancelledError ? 'Journey Cancelled' : 'Payment Failed'
    const errorMsg = cancelledError
      ? `This journey has been cancelled${
          paymentData.transactions.length
            ? ', we have cancelled the payment authorisation with your bank but this can take up to 4 hours.'
            : '.'
        }`
      : transactionError
      ? `Please pay your driver ${journeyFare} this journey.`
      : error.message

    return (
      <div className={cx(genericErrorBox, padding)}>
        <span className={loadingMsgMain}>{title}</span>
        <span className={loadingMsgSub}>{errorMsg}</span>
      </div>
    )
  }

  openCloseOverlay(show, e) {
    if (e) e.preventDefault()
    this.setState({
      overlayOpen: show,
    })
  }

  closeWelcomeMsg() {
    this.setState({
      showWelcomeMsg: false,
    })
  }

  paymentSuccess() {
    this.setState({
      paymentComplete: true,
      isLoading: false,
    })

    this.props.refetch()
  }

  setAdyenLoaded(a, b) {
    setTimeout(() => {
      if (!this.state.adyenLoaded) {
        this.setState({
          adyenLoaded: true,
        })
      }
    }, 100)
  }

  paymentError(err) {
    /*
        Accomodate all types of errors - GQL, Stripe, Server
    */
    let message = ''
    if (err && err.graphQLErrors) {
      _.each(err.graphQLErrors, (error, i) => {
        if (error.message) {
          message = message + error.message + '\n'
        }
      })
    } else {
      if (err.message) {
        message = err.message
      } else if (err.error && err.error.message) {
        message = err.error.message
      } else if (err.refusalReason) {
        message = err.refusalReason
      } else {
        message =
          "There's a problem connecting to our network. Waiting to reconnect..."
      }
    }

    this.setState({
      isLoading: false,
      overlayOpen: true,
      error: {
        message: message,
      },
    })
  }

  render() {
    const {
      isLoading,
      paymentComplete,
      overlayOpen,
      isComplete,
      paymentData,
      showWelcomeMsg,
      animatedTick,
      isCompleteWithoutAction,
      paymentWaiting,
    } = this.state
    const fleet = _.get(paymentData, 'driver.fleet', '')
    const fleetName = _.get(fleet, 'name', '')
    const gatewayStatus = _.get(
      paymentData,
      'transactions[0].gatewayStatus',
      null
    )
    const journeyCancelled = paymentData.status === 'CANCELLED'
    const transactionError =
      journeyCancelled ||
      gatewayStatus === 'ERROR' ||
      gatewayStatus === 'FAILED' ||
      _.get(paymentData, 'transactions[0].status', null) === 'CANCELLED'
    const grandTotal = _.get(paymentData, 'transactions[0].grandTotal', 0)

    const {
      paymentHeader,
      subHeader,
      mainTitle,
      paymentBoxWrapper,
      headerPara,
      opacityDown,
      boxPositionShift,
      paymentBoxBoxWrapper,
      paymentFormView,
      journeyComplete,
      marginTop,
      tickWrapper,
      para,
    } = this.props.classes

    const showTick = animatedTick ? 'tick animating' : 'tick'
    const { ADYEN_ENVIRONMENT } = process.env
    const titleOpacityClass = isLoading ? opacityDown : {}
    const boxPositionClass =
      isLoading || isComplete || paymentComplete ? boxPositionShift : {}
    const { loading } = this.props

    if (transactionError) {
      return this.getErrorView()
    }
    if (!loading && isCompleteWithoutAction) {
      return (
        <div className={journeyComplete}>
          <h3 className={mainTitle}>Journey Ended!</h3>
          <p className={para}>
            Look out for the Airpay SMS link to pay for your next journey by
            card.
          </p>
        </div>
      )
    }
    // Error comes from Apollo poll
    return (
      <>
        <Helmet onChangeClientState={(a, b) => this.setAdyenLoaded(a, b)}>
          <script src="https://pay.google.com/gp/p/js/pay.js" />
          <script
            src={`https://checkoutshopper-${ADYEN_ENVIRONMENT}.adyen.com/checkoutshopper/sdk/3.5.0/adyen.js`}
          />
          <link
            rel="stylesheet"
            href={`https://checkoutshopper-${ADYEN_ENVIRONMENT}.adyen.com/checkoutshopper/sdk/3.5.0/adyen.css`}
          />
        </Helmet>
        <CSSTransition
          in={loading}
          timeout={200}
          classNames="fade-in"
          unmountOnExit
        >
          {this.getLoadingMessage('Loading', true, true)}
        </CSSTransition>

        <CSSTransition
          in={!loading && isComplete}
          timeout={200}
          classNames="fade-in-up"
          unmountOnExit
          onEntered={() => this.animateTick(true)}
          onExited={() => this.animateTick(false)}
        >
          <div className={journeyComplete}>
            <div className={tickWrapper}>
              <div className={showTick}>
                <div className="tick-bg" />
                <div className="tick-icon">
                  <svg viewBox="0 0 24 24">
                    <polygon points="9 21 1 13 4 10 9 15 21 3 24 6 9 21" />
                  </svg>
                </div>
              </div>
            </div>

            <h3 className={mainTitle}>
              Payment
              <br />
              Successful
            </h3>
            <p className={headerPara}>
              You have now been charged
              <br />
              <strong>{`\u00A3${(grandTotal / 100).toFixed(2)}`}</strong> for
              this journey.
            </p>
            <p className={para}>
              Thank you for using Airpay and riding with {fleetName}.
            </p>
          </div>
        </CSSTransition>

        <CSSTransition
          in={paymentComplete && !isComplete}
          timeout={200}
          classNames="fade-in-up"
          unmountOnExit
        >
          {this.getPaymentCompleteMessage()}
        </CSSTransition>

        <CSSTransition
          in={paymentWaiting}
          timeout={200}
          classNames="fade-in-up"
          unmountOnExit
        >
          {this.getPaymentWaitingMessage()}
        </CSSTransition>

        <CSSTransition
          in={
            !loading &&
            !paymentComplete &&
            !isComplete &&
            !paymentWaiting &&
            !journeyCancelled
          }
          timeout={200}
          classNames="fade-in-up"
          unmountOnExit
        >
          <div className={paymentFormView}>
            <div className={cx(paymentHeader, titleOpacityClass)}>
              <h3 className={mainTitle}>{fleetName}</h3>
              <h6 className={subHeader}>Updating Payment Method</h6>
            </div>

            {isLoading &&
              this.getLoadingMessage('Processing Payment', true, false)}
            <div className={paymentBoxBoxWrapper}>
              <div className={cx(paymentBoxWrapper, boxPositionClass)}>
                {this.state.adyenLoaded && (
                  <AdyenCheckoutForm
                    isLoading={this.handleLoading}
                    paymentData={paymentData}
                    onSuccess={this.paymentSuccess}
                    onError={this.paymentError}
                  />
                )}
              </div>
            </div>
            <p className={cx(headerPara, boxPositionClass)}>
              {`You will be charged ${
                this.props.fixedCost
                  ? 'immediately'
                  : 'at the end of your journey'
              }.`}
            </p>
          </div>
        </CSSTransition>
        <OverlayView
          openCloseOverlay={this.openCloseOverlay}
          overlayOpen={overlayOpen}
        >
          <ErrorView
            error={this.state.error}
            close={() => this.openCloseOverlay(false)}
          />
        </OverlayView>
      </>
    )
  }
}

class PaymentViewQueryWrapper extends React.Component {
  constructor(props) {
    super(props)
  }
  shouldComponentUpdate() {
    return false
  }

  render() {
    const thisShortId = this.props.match.params.id
    const query = journeyById(thisShortId)
    const PaymentView = injectSheet(styler())(PaymentViewEl)

    return (
      <>
        <Query
          query={query}
          pollInterval={10000}
          onCompleted={this.props.updateJourneyData}
        >
          {({ loading, error, data, refetch }) => {
            if (_.isEmpty(data.journeys) && !loading) {
              return <NotFound />
            }
            return (
              <PaymentView
                loading={loading}
                error={error}
                data={data}
                refetch={refetch}
                route={this.props.match}
                fixedCost={this.props.fixedCost}
              />
            )
          }}
        </Query>
      </>
    )
  }
}

export default PaymentViewQueryWrapper
