import React from 'react'
import injectSheet from 'react-jss'
import styles from './styles'
import ReactSVG from 'react-svg'
import desktopSVG from '../../assets/images/hero-desktop.svg'
import logoSVG from '../../assets/images/logo-full-white.svg'
import logoPNG from '../../assets/images/airpay-logo-white.png'
import { get } from 'lodash'

class HeaderEl extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { logo, trackLink } = this.props.classes
    const { openTrackLink } = this.props
    const journeyStatus = get(this.props, 'journeyData.status', null)
    const validTrackingStatus =
      journeyStatus && ['ENROUTE', 'TRAVELLING'].includes(journeyStatus)
    const search = window.location.search
    const params = new URLSearchParams(search)
    const showTrackBtn = params.get('t') === '1'

    return (
      <div>
        <div className={logo}>
          <img src={logoPNG} />
        </div>
        {showTrackBtn && validTrackingStatus && (
          <a
            onClick={e => {
              e.preventDefault()
              openTrackLink(true)
            }}
            className={trackLink}
          >
            Track Driver
          </a>
        )}
      </div>
    )
  }
}

const Header = injectSheet(styles)(HeaderEl)
export default Header
