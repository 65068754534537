const style = {
  popoverBackground: {
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  innerWindow: {
    position: 'absolute',
    top: '160px',
    left: '10px',
    right: '10px',
    borderRadius: '10px',
  },
}

export default style
