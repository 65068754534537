import React from 'react'
import injectSheet from 'react-jss'
import { CSSTransition } from 'react-transition-group'
import styles from './trackingViewStyles'
import Websocket from 'react-websocket'
import taxiSVG from '../../assets/images/taxi-icon-top.svg'
import userPNG from '../../assets/images/user-icon-top.png'
import cx from 'classnames'
import ReactSVG from 'react-svg'
import closeSVG from '../../assets/images/white-x.svg'
import * as rp from 'request-promise-native'
import { get } from 'lodash'

class TrackingViewEl extends React.Component {
  constructor(props) {
    super(props)
    this.map = null
    this.userMarker = null
    this.driverMarker = null
    this.refWebSocket = null
    this.fitMap = this.fitMap.bind(this)
    this.replotPoints = this.replotPoints.bind(this)
    this.handleDriverLocationUpdate = this.handleDriverLocationUpdate.bind(this)
    this.close = this.close.bind(this)
    this.updateRoute = this.updateRoute.bind(this)
    this.emptyCollection = turf.featureCollection([])
    this.mapboxToken = process.env.MAPBOX_CLIENT_ACCESS_TOKEN
    this.websocketEndpoint = process.env.WEBSOCKET_ENDPOINT
    this.state = {
      journeyData: null,
      openInner: false,
      userLocation: null,
      driverLocation: null,
    }
    this.mapDiv = React.createRef()
  }

  close(e) {
    e.preventDefault()
    this.setState(
      {
        openInner: false,
      },
      () => {
        setTimeout(() => {
          this.props.close()
        }, 50)
      }
    )
  }

  getLocationPin(icon, width, height) {
    // create a DOM element for the marker
    const el = document.createElement('div')
    el.className = 'marker'
    el.style.backgroundImage = `url(${icon})`
    el.style.backgroundSize = `contain`
    el.style.backgroundRepeat = `no-repeat`
    el.style.width = `${width}px` // '18px'
    el.style.height = `${height}px` // '35px'
    return new mapboxgl.Marker(el).setLngLat([0, 0]).addTo(this.map)
  }

  replotPoints() {
    const { userLocation, driverLocation } = this.state
    if (userLocation && this.userMarker) {
      const { longitude: userLon, latitude: userlat } = userLocation
      this.userMarker.setLngLat([userLon, userlat])
    }
    if (driverLocation && this.driverMarker) {
      const {
        longitude: driverLon,
        latitude: driverlat,
        heading,
      } = driverLocation
      this.driverMarker.setLngLat([driverLon, driverlat])
      this.driverMarker.setRotation(Number(heading))
    }
    this.fitMap()
  }
  componentDidMount() {
    const mapDiv = this.mapDiv.current
    mapboxgl.accessToken = this.mapboxToken
    this.map = new mapboxgl.Map({
      container: mapDiv,
      zoom: 6,
      style: 'mapbox://styles/airpay/ckh1yfmg90y7s1ap7qpslhxr9',
      center: [-1.2011397, 52.6360565],
      dragRotate: false,
      dragPan: false,
      doubleClickZoom: false,
      scrollZoom: false,
      touchZoomRotate: false,
    })
    this.map.on('load', () => {
      this.map.addSource('route', {
        type: 'geojson',
        data: this.emptyCollection,
      })
      this.map.addLayer(
        {
          id: 'routeline-active',
          type: 'line',
          source: 'route',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#2e84ff',
            'line-width': 3,
            'line-opacity': 0.7,
          },
        },
        'waterway-label'
      )
    })

    this.userMarker = this.getLocationPin(userPNG, 30, 30)
    this.driverMarker = this.getLocationPin(taxiSVG, 25, 47)

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(location => {
        const { latitude, longitude } = location.coords
        this.setState(
          {
            userLocation: {
              latitude,
              longitude,
            },
          },
          () => {
            this.replotPoints()
          }
        )
      })
    } else {
      console.log('No geolocation available...')
    }
  }

  updateRoute(coords) {
    const query = `https://api.mapbox.com/directions/v5/mapbox/driving/${coords}?access_token=${mapboxgl.accessToken}`
    rp.get(query, {
      json: true,
    }).then(data => {
      const geo = get(data, 'routes[0].geometry', null)
      if (geo) {
        const routeLine = polyline.toGeoJSON(geo)
        this.map.getSource('route').setData(routeLine)
      }
    })
  }

  fitMap() {
    const { lat: dlat, lng: dlng } = this.driverMarker.getLngLat()
    const { lat: ulat, lng: ulng } = this.userMarker.getLngLat()
    // dont fit bounds if any coordinates are still zero
    if ([dlat, dlng, ulat, ulng].indexOf(0) > -1) return

    this.map.fitBounds(
      [
        [dlng, dlat],
        [ulng, ulat],
      ],
      {
        padding: { top: 70, bottom: 170, left: 70, right: 70 },
      }
    )
    this.updateRoute(`${dlng},${dlat};${ulng},${ulat}`)
  }

  handleDriverLocationUpdate(data) {
    let result = JSON.parse(data)
    const { lat, lng, heading, vehicle_reg } = result
    if (!lat || !lng) return
    this.setState(
      {
        driverLocation: {
          longitude: lng,
          latitude: lat,
          heading,
          vehicle_reg,
        },
      },
      () => {
        this.replotPoints()
      }
    )
  }

  render() {
    const { journeyData, classes } = this.props
    const {
      mapDiv,
      mapOverlay,
      regPlate,
      driverStateDesc,
      statusTag,
      leftBit,
      rightBit,
      inlineLoading,
      loader,
      greyDot,
      closeIcon,
    } = classes
    const { icabbiTripId, driver } = journeyData
    const { key: fleetKey } = driver.fleet
    const { driverLocation } = this.state
    const driverName = driver.name ? driver.name : 'Driver reg'
    const statusTagStyle =
      classes[`journey-${journeyData.status.toLowerCase()}`]

    return (
      <>
        <Websocket
          url={this.websocketEndpoint}
          debug={true}
          ref={Websocket => {
            this.refWebSocket = Websocket
          }}
          onOpen={() => {
            this.refWebSocket.sendMessage(
              JSON.stringify({
                action: 'subscribeToGPS',
                fleetKey,
                tripId: icabbiTripId.toString(),
              })
            )
          }}
          onMessage={this.handleDriverLocationUpdate}
        />
        <ReactSVG className={closeIcon} src={closeSVG} />

        <div id="map" ref={this.mapDiv} className={mapDiv}></div>
        <div className={mapOverlay}>
          {!driverLocation ? (
            <>
              <div className={cx('lds-ellipsis', loader)}>
                <div className={greyDot} />
                <div className={greyDot} />
                <div className={greyDot} />
                <div className={greyDot} />
              </div>
              <span className={inlineLoading}>Locating your driver...</span>
            </>
          ) : (
            <>
              <div className={leftBit}>
                <p className={driverStateDesc}>Vehicle Reg</p>
                <p className={regPlate}>{driverLocation.vehicle_reg}</p>
              </div>
              <div className={rightBit}>
                <span className={cx(statusTag, statusTagStyle)}>
                  {journeyData.status.toUpperCase()}
                </span>
              </div>
            </>
          )}
        </div>
      </>
    )
  }
}

const TrackingView = injectSheet(styles)(TrackingViewEl)
export default TrackingView
