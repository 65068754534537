import React from 'react'
import injectSheet from 'react-jss'
import styles from './termsViewStyles'
import ReactSVG from 'react-svg'
import leftArrow from '../../assets/images/arrow-left.svg'
import { CSSTransition } from 'react-transition-group'

class TermsViewEl extends React.Component {
  constructor(props) {
    super(props)
    this.close = this.close.bind(this)
    this.state = {
      openInner: false,
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.termsOpen && !this.state.openInner) {
      setTimeout(() => {
        this.setState({
          openInner: true,
        })
      }, 100)
    }
  }

  close(e) {
    e.preventDefault()
    this.setState(
      {
        openInner: false,
      },
      () => {
        setTimeout(() => {
          this.props.close()
        }, 50)
      }
    )
  }

  render() {
    const {
      background,
      panel,
      content,
      actionBar,
      linkIcon,
      subHeader,
      para,
      backLink,
      paraHeader,
    } = this.props.classes
    return (
      <CSSTransition
        in={this.props.termsOpen}
        timeout={200}
        classNames="fade-in"
        unmountOnExit
      >
        <div className={background}>
          <CSSTransition
            in={this.state.openInner}
            timeout={200}
            classNames="slide-in-left"
            unmountOnExit
          >
            <div className={panel}>
              <div className={content}>
                <h1 className={subHeader}>
                  AirPay Global Limited Terms of Use
                </h1>
                <p className={paraHeader}>
                  Please read these terms of use carefully
                </p>
                <p className={para}>
                  By accessing and using the Service (as defined below), you
                  agree to comply with these Terms of Use.
                </p>
                <h2 className={paraHeader}>
                  Who we are and what this agreement does
                </h2>
                <p className={para}>
                  We are AirPay Global Limited (Company Number 11824264) of 148
                  Bradford Road, Wakefield, West Yorkshire, United Kingdom, WF1
                  2AP (AirPay): Our software enables you to make in-car payments
                  for transportation services via your preferred payment service
                  provider to your Transportation Provider using your mobile
                  phone as permitted under these terms (the Service).
                </p>
                <h2 className={paraHeader}>Your privacy</h2>
                <p className={para}>
                  We only use any personal data we collect through your use of
                  the Service in the ways set out in our privacy policy unless
                  you are using a specific service to which a different privacy
                  policy applies, for example Apple Pay, Google Pay, PayPal,
                  etc. Please be aware that internet transmissions are never
                  completely private or secure and that any message or
                  information you send using the Service may be read or
                  intercepted by others, even if there is a special notice that
                  a particular transmission is encrypted.
                </p>
                <h2 className={paraHeader}>Terms of Use</h2>
                <p className={para}>
                  We do not provide transportation services. Transportation
                  services are provided to you under your contract with the
                  company from which you booked the transportation services (the
                  Transport Provider) and to which we are not a part. These
                  Terms of Use (Terms) apply to your use of the Service defined
                  above but not to the booking or transportation service
                  provided by your Transport Provider or to the separate terms
                  of your payment service provider.
                </p>
                <h2 className={paraHeader}>Additional terms also apply</h2>
                <p className={para}>
                  Depending on which payment service provider you choose, the
                  ways in which you can use the Service may also be controlled
                  by the rules and policies of your chosen payment service
                  provider.
                </p>
                <h2 className={paraHeader}>Payment</h2>
                <p className={para}>
                  The payment that you make will include the cost of the
                  transportation services and our administration fee for the
                  Service per payment transaction. We have no control over the
                  cost of the transportation services and it is your
                  responsibility to ensure that you are fully informed as to
                  such costs. We accept the following payment methods: Card
                  payments (Visa, MasterCard, American Express, Diners Club,
                  Maestro) PayPal, Apple Pay, Google Pay. Your payment will only
                  be complete when we’ve sent you a confirmation by SMS and
                  after the full price has been paid. Once we have received
                  payment from you, we will remit the full cost of the
                  transportation service to your Transport Provider retaining
                  only the administration fee for each transaction. If you pay
                  using a credit or debit card that’s linked to a bank account
                  set up in a different currency, you may be charged a fee by
                  your bank (for example, commissions).
                </p>
                <h2 className={paraHeader}>Refunds</h2>
                <p className={para}>
                  We may permit changes to a Booking by a Passenger on whose
                  behalf the Booking was made, and you acknowledge that you are
                  liable for all Charges and Additional Charges for the Booking
                  as changed. If a Booking change or changes results in a full
                  or partial refund to you, we will credit the Card used to make
                  the Booking. Furthermore, any cancellations of bookings made
                  by the drivers/fleets or airpay will result in a full refund,
                  if payment taken, or monies released if taken as a
                  pre-authorisation/pre-capture.
                </p>

                <h2 className={paraHeader}>
                  Support for the service and how to tell us about problems
                </h2>
                <p className={para}>
                  Support. If you want to learn more about the Service or have
                  any problems using it please take a look at our support
                  resources at www.air-pay.co.uk.
                </p>
                <h2 className={paraHeader}>
                  Contacting us (including with complaints).
                </h2>
                <p className={para}>
                  If you think the Service is faulty or misdescribed or wish to
                  contact us for any other reason please email our customer
                  service team at support@air-pay.co.uk.
                </p>
                <h2 className={paraHeader}>
                  How we will communicate with you.
                </h2>
                <p className={para}>
                  If we have to contact you we will do so by telephone, email or
                  by SMS, using the contact details you have provided to us.
                </p>

                <h2 className={paraHeader}>
                  You must be over 18 to accept these terms and use the service
                </h2>
                <p className={para}>
                  You must be 18 or over to accept these terms and use the
                  service.
                </p>
                <h2 className={paraHeader} />

                <h2 className={paraHeader}>
                  Support for the Service and how to tell us about problems
                </h2>
                <p className={para}>
                  Support. If you want to learn more about the Service or have
                  any problems using it please take a look at our support
                  resources at https://www.air-pay.co.uk/support. Contacting us
                  (including with complaints). If you think the Service is
                  faulty or misdescribed or wish to contact us for any other
                  reason please email our customer service team at
                  support@air-pay.co.uk or call them on +44(0)1134660053. How we
                  will communicate with you. If we have to contact you we will
                  do so by telephone, email or by SMS, using the contact details
                  you have provided to us. You must be 18 to accept these terms
                  and use the Service
                </p>
                <h2 className={paraHeader}>Changes to these terms</h2>
                <p className={para}>
                  We may need to change these terms or the Service to reflect
                  changes in law or best practice or to deal with additional
                  features which we introduce. We will give you notify you of a
                  change when you next start the Service. If you do not accept
                  the notified changes, please do not to use the Service.
                </p>
                <h2 className={paraHeader}>
                  If someone else owns the phone or device you are using
                </h2>
                <p className={para}>
                  If you use the Service on a phone or other device not owned by
                  you, you must have the owner's permission to do so. You will
                  be responsible for complying with these terms, whether or not
                  you own the phone or other device.
                </p>
                <p className={para}>
                  We may collect technical data about your device By using the
                  Service, you agree to us collecting and using technical
                  information about the devices you use the Service on and
                  related software, hardware and peripherals to improve our
                  services to you.
                </p>
                <p className={para}>
                  We are not responsible for other websites you link to The
                  Service may contain links to other independent websites or
                  application programming interfaces which are not provided by
                  us. Such independent sites are not under our control, and we
                  are not responsible for and have not checked and approved
                  their content or their privacy policies (if any). You will
                  need to make your own independent judgement about whether to
                  use any such independent sites.
                </p>
                <h2 className={paraHeader}>Acceptable use restrictions</h2>
                <p className={para}>
                  You must: not disassemble, de-compile, reverse engineer or
                  create derivative works based on the whole or any part of the
                  Service; not use the Service in any unlawful manner, for any
                  unlawful purpose, or act fraudulently or maliciously, for
                  example, by hacking into or inserting malicious code, such as
                  viruses, or harmful data, into the Service or any operating
                  system; not use the Service in a way that could damage,
                  disable, overburden, impair or compromise our systems or
                  security or interfere with other users; and not collect or
                  harvest any information or data from the Service or our
                  systems or attempt to decipher any transmissions to or from
                  the servers running the Service.
                </p>
                <h2 className={paraHeader}>Intellectual property rights</h2>
                <p className={para}>
                  All intellectual property rights in the Service throughout the
                  world belong to us (or our licensors) and the rights in the
                  Service are licensed (not sold) to you. You have no
                  intellectual property rights in, or to, the Service other than
                  the right to use them in accordance with these terms.
                </p>
                <h2 className={paraHeader}>
                  Our responsibility for loss or damage suffered by you
                </h2>
                <p className={para}>
                  We are responsible to you for foreseeable loss and damage
                  caused by us. If we fail to comply with these terms, we are
                  responsible for loss or damage you suffer that is a
                  foreseeable result of our breaking these terms or our failing
                  to use reasonable care and skill, but we are not responsible
                  for any loss or damage that is not foreseeable. Loss or damage
                  is foreseeable if either it is obvious that it will happen or
                  if, at the time you accepted these terms, both we and you knew
                  it might happen. We do not exclude or limit in any way our
                  liability to you where it would be unlawful to do so. This
                  includes liability for death or personal injury caused by our
                  negligence or the negligence of our employees, agents or
                  subcontractors or for fraud or fraudulent misrepresentation.
                  When we are liable for damage to your property. If defective
                  digital content that we have supplied damages a device or
                  digital content belonging to you, we will either repair the
                  damage or pay you compensation. We are not liable for business
                  losses. The Service is for private use. If you use the Service
                  for any commercial, business or resale purpose we will have no
                  liability to you for any loss of profit, loss of business,
                  business interruption, or loss of business opportunity. We are
                  not responsible for events outside our control. We are not
                  liable if our provision of the Service is prevented or delayed
                  by an event outside our control.
                </p>
                <h2 className={paraHeader}>
                  We may transfer this agreement to someone else
                </h2>
                <p className={para}>
                  We may transfer our rights and obligations under these terms
                  to another organisation. We will always tell you in writing if
                  this happens and we will ensure that the transfer will not
                  affect your rights under the contract.
                </p>
                <h2 className={paraHeader}>
                  You need our consent to transfer your rights to someone else
                </h2>
                <p className={para}>
                  You may only transfer your rights or your obligations under
                  these terms to another person if we agree in writing.
                </p>
                <h2 className={paraHeader}>No rights for third parties</h2>
                <p className={para}>
                  This agreement does not give rise to any rights under the
                  Contracts (Rights of Third Parties) Act 1999 to enforce any
                  term of this agreement.
                </p>
                <h2 className={paraHeader}>
                  If a court finds part of this contract illegal, the rest will
                  continue in force
                </h2>
                <p className={para}>
                  Each of the paragraphs of these terms operates separately. If
                  any court or relevant authority decides that any of them are
                  unlawful, the remaining paragraphs will remain in full force
                  and effect.
                </p>
                <h2 className={paraHeader}>
                  Even if we delay in enforcing this contract, we can still
                  enforce it later
                </h2>
                <p className={para}>
                  If we do not insist immediately that you do anything you are
                  required to do under these terms, or if we delay in taking
                  steps against you in respect of your breaking this contract,
                  that will not mean that you do not have to do those things and
                  it will not prevent us taking steps against you at a later
                  date.
                </p>
                <h2 className={paraHeader}>
                  Which laws apply to this contract and where you may bring
                  legal proceedings
                </h2>
                <p className={para}>
                  These terms are governed by English law and you can bring
                  legal proceedings in respect of the products in the English
                  courts. If you live in Scotland you can bring legal
                  proceedings in respect of the products in either the Scottish
                  or the English courts. If you live in Northern Ireland you can
                  bring legal proceedings in respect of the products in either
                  the Northern Irish or the English courts.
                </p>
                <h2 className={paraHeader}>Alternative dispute resolution</h2>
                <p className={para}>
                  Alternative dispute resolution is a process where an
                  independent body considers the facts of a dispute and seeks to
                  resolve it, without you having to go to court. If you are not
                  happy with how we have handled any complaint, you may want to
                  contact the alternative dispute resolution provider we use.
                  You can submit a complaint to Centre for Effective Dispute
                  Resolution (CEDR) via their website at https://www.cedr.com/.
                  CEDR will not charge you for making a complaint and if you are
                  not satisfied with the outcome you can still bring legal
                  proceedings. In addition, please note that disputes may be
                  submitted for online resolution to the European Commission
                  Online Dispute Resolution platform.
                </p>
              </div>
              <div className={actionBar}>
                <a href="#_" className={backLink} onClick={this.close}>
                  <ReactSVG className={linkIcon} src={leftArrow} />
                  Back
                </a>
              </div>
            </div>
          </CSSTransition>
        </div>
      </CSSTransition>
    )
  }
}

const TermsView = injectSheet(styles)(TermsViewEl)
export default TermsView
