import footerBg from '../../assets/images/background-footer.svg'
import infoIcon from '../../assets/images/info.svg'

const styles = {
  footer: {
    bottom: 0,
    width: '100%',
  },

  link: {
    color: '#fff',
    padding: '20px 22px',
    display: 'inline-block',
    backgroundImage: `url(${infoIcon})`,
    backgroundPosition: 'left center',
    backgroundRepeat: 'no-repeat',
    marginLeft: '20px',
    backgroundSize: '14px',
    fontSize: '15px',
    fontWeight: 600,
    zIndex: 20,
    position: 'absolute',
    bottom: 0,
  },
}

export default styles
