import React from 'react'
import injectSheet from 'react-jss'
import styles from './notFoundStyles'

class NotFound extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { notFoundWrapper, header, para, link } = this.props.classes
    return (
      <div className={notFoundWrapper}>
        <h3 className={header}>Journey not found</h3>
        <p className={para}>There is no journey associated with this ID.</p>
        <a className={link} href="/">
          Back to Homepage
        </a>
      </div>
    )
  }
}

export default injectSheet(styles)(NotFound)
