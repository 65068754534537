import React from 'react'
import injectSheet from 'react-jss'
import styles from './routeErrorStyles'

const routeError = props => {
  const { noticeBox, title, para, retryBtn } = props.classes
  const navigate = e => {
    e.preventDefault()
    props.history.push(`/${props.match.params.id}`)
  }
  return (
    <div className={noticeBox}>
      <h2 className={title}>Authorisation Error</h2>
      <p className={para}>
        Sorry, your bank did not authorise this payment. Please try again.
      </p>
      <a className={retryBtn} href="#_" onClick={navigate}>
        Retry
      </a>
    </div>
  )
}

const RouteError = injectSheet(styles)(routeError)

export default RouteError
