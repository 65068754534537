import closeIcon from '../../assets/images/e-remove.svg'
import { sharedContentStyles } from '../overlayView/overlayViewStyles'

const styles = {
  mapDiv: {
    height: '75vh',
    margin: '-20px',
    borderRadius: '14px 14px 0px 0px',
  },
  mapOverlay: {
    position: 'fixed',
    backgroundColor: '#fff',
    height: '80px',
    bottom: '48px',
    left: '20px',
    right: '20px',
    borderRadius: '5px',
    boxShadow: '0px 3px 20px #00000047',
    padding: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  regPlate: {
    display: 'block',
    fontSize: '25px',
    color: '#2862e2',
    paddingTop: '4px',
  },
  driverStateDesc: {
    display: 'block',
    fontSize: '13px',
    paddingTop: '0px',
    color: '#5d5d5d',
  },
  leftBit: {},
  rightBit: {},
  statusTag: {
    right: 0,
    color: '#6E7FA6',
    fontSize: '13px',
    fontWeight: 600,
  },
  inlineLoading: {
    color: '#929292',
    position: 'absolute',
    left: '93px',
    fontSize: '14px',
  },
  closeIcon: {
    position: 'absolute',
    top: '-56px',
    right: '20px',
    height: '44px',
    width: '44px',
    pointerEvents: 'none',
    '& svg': {
      width: '32px',
      height: '32px',
      padding: '7px',
    },
  },
  loader: {},
  greyDot: {
    background: '#b9b9b9!important',
  },
  'journey-booked': {
    color: '#6E7FA6',
  },
  'journey-enroute': {
    color: '#2862e2',
  },
  'journey-travelling': {
    color: '#E59410',
  },
  'journey-completed': {
    color: '#1BBD61',
  },
  'journey-expired': {
    color: '#BD321B',
  },
  'journey-cancelled': {
    color: '#BD321B',
  },
}

export default styles
