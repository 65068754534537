import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route } from 'react-router-dom'
import injectSheet from 'react-jss'
import { get } from 'lodash'
import '../assets/styles/global.scss'
import styles from './styles'
import PaymentPage from '../paymentView/paymentView/paymentView'
import Header from '../components/header/header'
import Footer from '../components/footer/footer'
import OverlayView from '../components/overlayView/overlayView'
import TermsView from '../components/termsView/termsView'
import TrackingView from '../components/trackingView/trackingView'
import PrivacyView from '../components/privacyView/privacyView'
import InfoView from '../components/infoView/infoView'
import PassengerNoticeView from '../components/passengerNoticeView/passengerNoticeView'
import ErrorView from '../components/errorView/errorView'
import RouteError from '../components/routeError/routeError'
import client from '../network/network'
import NumberEntryView from '../numberEntryView/numberEntryView'
import TransactionViewElement from '../transactionsView/transactionsView'

import { ApolloProvider } from 'react-apollo'

class AppEl extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      overlayOpen: false,
      termsOpen: false,
      privacyOpen: false,
      noticeOpen: false,
      partnerCharge: 0,
      estimatedJourneyCharge: 0,
      estimateFromAddress: false,
      trackViewOpen: false,
      journeyData: {},
    }
    this.openCloseOverlay = this.openCloseOverlay.bind(this)
    this.openCloseTerms = this.openCloseTerms.bind(this)
    this.openClosePrivacy = this.openClosePrivacy.bind(this)
    this.openCloseCustomerNotice = this.openCloseCustomerNotice.bind(this)
    this.updateJourneyData = this.updateJourneyData.bind(this)
    this.openCloseTrackView = this.openCloseTrackView.bind(this)
  }

  openCloseOverlay(show, e) {
    if (e) e.preventDefault()
    this.setState({
      overlayOpen: show,
    })
  }

  openCloseCustomerNotice(show, e) {
    if (e) e.preventDefault()
    this.setState({
      noticeOpen: show,
    })
  }

  openCloseTerms(show) {
    this.setState({
      termsOpen: show,
    })
  }
  openClosePrivacy(show) {
    this.setState({
      privacyOpen: show,
    })
  }

  setJourney(journeyData) {
    this.setState({
      journeyData,
    })
  }

  openCloseTrackView(show) {
    this.setState({
      trackViewOpen: show,
    })
  }

  updateJourneyData(journey) {
    const hasJourney = get(journey, 'journeys', []).length > 0
    if (!hasJourney) return false

    const journeyData = get(journey, 'journeys[0]', {})
    const isComplete = get(journey, 'journeys[0].status', '') === 'COMPLETED'
    const isCanceled = get(journey, 'journeys[0].status', '') === 'CANCELLED'
    const hasTransaction =
      get(journey, 'journeys[0].transactions', []).length > 0
    let partnerCharge = get(
      journey,
      'journeys[0].driver.fleet.partner.serviceCharge',
      500
    )
    const fleetAbsorbsCharge = get(
      journey,
      'journeys[0].driver.fleet.absorbPlatformCharge',
      false
    )
    const excludePlatformCharge = get(
      journey,
      'journeys[0].driver.fleet.excludePlatformCharge',
      false
    )
    const overrideDefaultPlatformCharge = get(
      journey,
      'journeys[0].driver.fleet.overrideDefaultPlatformCharge',
      false
    )
    const overrideDefaultPlatformChargeValue = get(
      journey,
      'journeys[0].driver.fleet.overrideDefaultPlatformChargeValue',
      false
    )

    if (overrideDefaultPlatformCharge && overrideDefaultPlatformChargeValue) {
      partnerCharge = overrideDefaultPlatformChargeValue
    }

    if (fleetAbsorbsCharge || excludePlatformCharge) {
      partnerCharge = 0
    }

    const fleetCharge = get(journey, 'journeys[0].driver.fleet.fleetCharge', 10)
    const fleetName = get(
      journey,
      'journeys[0].driver.fleet.name',
      'this fleet'
    )
    const estimateFromAddress = get(
      journey,
      'journeys[0].estimateFromAddress',
      false
    )
    const estimatedJourneyCharge = get(
      journey,
      'journeys[0].estimatedCost',
      800
    )
    this.setState({
      journeyData,
      fleetName: fleetName,
      partnerCharge: partnerCharge + fleetCharge,
      estimateFromAddress,
      estimatedJourneyCharge: `\u00A3${(estimatedJourneyCharge / 100).toFixed(
        2
      )}`,
      noticeOpen: !hasTransaction && !isComplete && !isCanceled,
    })
  }

  render() {
    const { appWrapper } = this.props.classes
    const {
      overlayOpen,
      termsOpen,
      privacyOpen,
      noticeOpen,
      partnerCharge,
      estimatedJourneyCharge,
      estimateFromAddress,
      fleetName,
      journeyData,
      trackViewOpen,
    } = this.state

    return (
      <div className={appWrapper}>
        <ApolloProvider client={client}>
          <Header
            journeyData={journeyData}
            openTrackLink={this.openCloseTrackView}
          />
          <BrowserRouter>
            <Route
              exact
              path="/"
              render={routeProps => <NumberEntryView {...routeProps} />}
            />
            <Route
              exact
              path="/s/:journeysViewCode"
              render={routeProps => <TransactionViewElement {...routeProps} />}
            />
            <Route
              exact
              path="/:id"
              render={routeProps => (
                <PaymentPage
                  {...routeProps}
                  updateJourneyData={this.updateJourneyData}
                />
              )}
            />
            <Route
              exact
              path="/:id/errors"
              render={routeProps => <RouteError {...routeProps} />}
            />
          </BrowserRouter>
          <Footer openCloseOverlay={this.openCloseOverlay} />

          <OverlayView
            openCloseOverlay={this.openCloseOverlay}
            overlayOpen={overlayOpen}
          >
            <InfoView
              fleetName={fleetName}
              partnerCharge={partnerCharge}
              openTerms={this.openCloseTerms}
              openPrivacy={this.openClosePrivacy}
              close={e => this.openCloseOverlay(false, e)}
            />
          </OverlayView>

          <OverlayView
            openCloseOverlay={this.openCloseTrackView}
            overlayOpen={trackViewOpen}
          >
            <TrackingView
              trackingOpen={trackViewOpen}
              journeyData={journeyData}
              close={e => this.openCloseTrackView(false, e)}
            />
          </OverlayView>

          <OverlayView
            openCloseOverlay={this.openCloseCustomerNotice}
            overlayOpen={noticeOpen}
          >
            <PassengerNoticeView
              partnerCharge={partnerCharge}
              estimatedJourneyCharge={estimatedJourneyCharge}
              estimateFromAddress={estimateFromAddress}
              close={e => this.openCloseCustomerNotice(false, e)}
              fixedCost={journeyData.fixedCost}
            />
          </OverlayView>

          <TermsView
            openCloseTerms={this.openCloseTerms}
            termsOpen={termsOpen}
            close={() => this.openCloseTerms(false)}
          />
          <PrivacyView
            openClosePrivacy={this.openClosePrivacy}
            privacyOpen={privacyOpen}
            close={() => this.openClosePrivacy(false)}
          />
        </ApolloProvider>
      </div>
    )
  }
}

const App = injectSheet(styles)(AppEl)
ReactDOM.render(<App />, document.querySelector('.react-container'))
