import overlayStyles, {
  sharedContentStyles,
} from '../overlayView/overlayViewStyles'

const styles = {
  ...overlayStyles,
  ...sharedContentStyles,
}

export default styles
