import React from 'react'
import injectSheet from 'react-jss'
import styles from './styles'

class FooterEl extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { footer, link, graphic } = this.props.classes
    return (
      <>
        <div className={footer}>
          <a
            className={link}
            href="#_"
            onClick={e => this.props.openCloseOverlay(true, e)}
          >
            Terms &amp; Conditions
          </a>
        </div>
      </>
    )
  }
}

const Footer = injectSheet(styles)(FooterEl)
export default Footer
