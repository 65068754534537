import React from 'react'
import injectSheet from 'react-jss'
import styles from './privacyViewStyles'
import ReactSVG from 'react-svg'
import leftArrow from '../../assets/images/arrow-left.svg'
import { CSSTransition } from 'react-transition-group'

class PrivacyViewEl extends React.Component {
  constructor(props) {
    super(props)
    this.close = this.close.bind(this)
    this.state = {
      openInner: false,
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.privacyOpen && !this.state.openInner) {
      setTimeout(() => {
        this.setState({
          openInner: true,
        })
      }, 100)
    }
  }

  close(e) {
    e.preventDefault()
    this.setState(
      {
        openInner: false,
      },
      () => {
        setTimeout(() => {
          this.props.close()
        }, 50)
      }
    )
  }

  render() {
    const {
      background,
      panel,
      content,
      actionBar,
      linkIcon,
      subHeader,
      para,
      backLink,
      paraHeader,
      bulletPoints,
    } = this.props.classes
    return (
      <CSSTransition
        in={this.props.privacyOpen}
        timeout={200}
        classNames="fade-in"
        unmountOnExit
      >
        <div className={background}>
          <CSSTransition
            in={this.state.openInner}
            timeout={200}
            classNames="slide-in-left"
            unmountOnExit
          >
            <div className={panel}>
              <div className={content}>
                <h1 className={subHeader}>Privacy Policy</h1>
                <h2 className={paraHeader}>Introduction</h2>
                <p className={para}>
                  We are committed to protecting and respecting your privacy.
                  Everyone has rights with regard to the way in which their
                  personal information is handled. During the course of our
                  activities we will collect, store and process personal
                  information about our customers, suppliers and other third
                  parties, and we recognise that the correct and lawful
                  treatment of this data will maintain confidence in the
                  organisation and will provide for successful business
                  operations. This policy sets out the basis on which any
                  personal information we collect from you, or that you provide
                  to us, will be processed by us. Please read the following
                  carefully to understand our views and practices regarding your
                  personal information and how we will treat it. For the purpose
                  of UK data protection laws, the data controller is Airpay
                  Global Limited of 148 Bradford Road, Wakefield, West
                  Yorkshire, United Kingdom, WF1 2AP.
                </p>
                <h2 className={paraHeader}>Data Protection Principles</h2>
                <p className={para}>
                  When processing your information, we must comply with the six
                  enforceable principles of good practice. These provide that
                  your personal information must be:
                </p>
                <ul className={bulletPoints}>
                  <li>
                    Processed lawfully, fairly and in a transparent manner,
                  </li>
                  <li>
                    Processed for specified, explicit and legitimate purposes,
                  </li>
                  <li>Adequate, relevant and limited to what is necessary,</li>
                  <li>Accurate and kept up-to-date,</li>
                  <li>Kept for no longer than is necessary, and</li>
                  <li>
                    Processed in a manner than ensures appropriate security.
                  </li>
                </ul>
                <h2 className={paraHeader}>Information you give us</h2>
                <p className={para}>
                  We may collect, use, store and transfer different kinds of
                  personal information about you, including:
                </p>
                <ul className={bulletPoints}>
                  <li>
                    <strong>Identity Data</strong>
                    Such as your name, username or similar identifier, marital
                    status, title, date of birth, gender
                  </li>

                  <li>
                    <strong>Contact Data</strong>
                    Such as your billing address, delivery address, email
                    address and telephone numbers,
                  </li>

                  <li>
                    <strong>Financial Data</strong>
                    Such as bank account and payment card details, products and
                    services you purchase from us,
                  </li>

                  <li>
                    <strong>Transaction Data</strong>
                    Including details about payments to and from you, and other
                    details of products and services you purchase from us,
                  </li>
                  <li>
                    <strong>Company Data</strong>
                    Including the name of the organisation you represent, and
                    that organisation’s
                  </li>

                  <li>
                    <strong>Technical Data</strong>
                    Including IP addresses, your log-in data, browser type and
                    version, time- zone setting and location, browser plug-in
                    types and versions, operating system and platform and other
                    technology on the devices you use to access our website,
                  </li>

                  <li>
                    <strong>Profile Data</strong>
                    Such as your username, password, purchases or orders made by
                    you, your interests, preferences, feedback and survey
                    responses,
                  </li>

                  <li>
                    <strong>Usage Data</strong>
                    Including information about how you use our website,
                    products and services, and
                  </li>

                  <li>
                    <strong>Marketing Data</strong>
                    Such as your preferences in receiving marketing from us and
                    our third parties, and your communication preferences.
                  </li>
                </ul>
                <h2 className={paraHeader}>"Special Category" Data</h2>
                <p className={para}>
                  Information relating to your racial or ethnic origin,
                  political opinions, religious or philosophical beliefs, trade
                  union membership, health, criminal convictions, sex life or
                  sexual orientation, or certain types of genetic or biometric
                  data is known as ‘special category’ data. During the course of
                  dealing with you, we do not expect to collect any ‘special
                  category’ data about you.
                </p>
                <h2 className={paraHeader}>
                  How we collect your personal information
                </h2>
                <p className={para}>
                  We may obtain personal information by directly interacting
                  with you, such as:
                </p>
                <ul className={bulletPoints}>
                  <li>Creating an account on our website, </li>

                  <li>
                    Participating in discussion boards or other social media
                    functions on our website,
                  </li>

                  <li>
                    Entering a competition, promotion or survey organised by us,
                    or otherwise providing us with feedback,
                  </li>

                  <li>
                    Subscribing to our services or publications, or otherwise
                    requesting marketing material to be sent to you,
                  </li>

                  <li>Meeting you at events, or </li>

                  <li>
                    Corresponding with us by phone, email, letters or otherwise.
                  </li>
                </ul>
                <p className={para}>
                  We may obtain personal information via automated technology
                  when you interact with our website by using cookies, server
                  logs and other similar technologies. We may also collect
                  personal information about you from third parties or
                  publicly-available sources, such as:
                </p>
                <ul className={bulletPoints}>
                  <li>Your employee where your employee is our customer</li>
                  <li>
                    Analytics providers (such as Google), advertising networks
                  </li>
                  <li>Search information providers </li>
                  <li>
                    Providers of technical, payment and delivery services,
                  </li>
                  <li>Companies House, LinkedIn and the electoral register.</li>
                </ul>
                <h2 className={paraHeader}>
                  How we use your personal information
                </h2>
                <p className={para}>
                  We will only use your personal information when the law allows
                  us to. Most commonly, we will use your personal information in
                  the following circumstances:
                </p>
                <ul className={bulletPoints}>
                  <li>You have given us consent</li>

                  <li>
                    We need to perform a contract we are about to enter into, or
                    have entered into, with you
                  </li>

                  <li>
                    Where it is necessary for our or a third party’s legitimate
                    interests, and your interests and rights do not override
                    those interests, or
                  </li>

                  <li>
                    Where we need to comply with a legal or regulatory
                    obligation
                  </li>
                </ul>
                <p className={para}>
                  We will only use ‘special category’ information:
                </p>
                <ul className={bulletPoints}>
                  <li>Provided we have your explicit consent to use it</li>

                  <li>
                    Where we believe that we need to use that data to protect
                    your vital interests where you are not able to provide us
                    with your explicit consent
                  </li>

                  <li>
                    Where it is necessary for reasons of substantial public
                    interest
                  </li>

                  <li>
                    Where you have previously made that data public knowledge,
                    or
                  </li>
                  <li>
                    If we need to use that data to establish, exercise or
                    defence legal claims.
                  </li>
                </ul>
                <h2 className={paraHeader}>
                  Purposes for which we will use your personal information
                </h2>
                <p className={para}>
                  We may use your personal information for a number of different
                  purposes. For each purpose, we are required to confirm the
                  ‘legal basis’ that allows us to use your information, as
                  follows:
                </p>
                <ul className={bulletPoints}>
                  <li>
                    <strong> To register you as a new customer</strong>
                    It will be necessary for the performance of the contract
                    between you and us
                  </li>
                  <li>
                    <strong>
                      To process your order and, if accepted, to deliver the
                      products to you (including managing payments, fees and
                      charges)
                    </strong>
                    It will be necessary for the performance of the contract
                    between you and us
                  </li>
                  <li>
                    <strong>To obtain further information about you</strong>
                    It will be necessary for our legitimate business interests,
                    namely to ensure we are fully aware of all issues relating
                    to the products and services we are providing
                  </li>
                  <li>
                    <strong>To collect and recover money owed to us</strong>
                    It will be necessary for our legitimate business interests,
                    namely to ensure we receive payment for products that you
                    have ordered from us
                  </li>
                  <li>
                    <strong>
                      To notify you about changes to our terms of sale or this
                      privacy policy
                    </strong>
                    It will be necessary for our legitimate business interests,
                    namely to ensure you are aware of our current terms and
                    conditions
                  </li>
                  <li>
                    <strong>
                      To administer our website and for internal operations,
                      including troubleshooting, data analysis, testing,
                      research, statistical and survey purposes, to allow you to
                      participate in interactive features of our service, when
                      you choose to do so, to measure or understand the
                      effectiveness of advertising we serve to you and others,
                      and to deliver relevant advertising to you and to make
                      suggestions and recommendations to you and other users of
                      our website about goods or services that may interest you
                      or them
                    </strong>
                    It will be necessary for our legitimate business interests
                    to ensure you receive the best experience possible when
                    accessing and using our website. We will comply with our
                    cookie policy when processing this information
                  </li>
                  <li>
                    <strong>
                      To enable you to participate in a prize draw, competition
                      or complete a survey
                    </strong>
                    It will be necessary for our legitimate business interests,
                    namely to study how customers use our products, to develop
                    them and help grow our business
                  </li>
                  <li>
                    <strong>
                      To provide you with information about special offers and
                      other products we sell that are similar to those that you
                      have already received from us
                    </strong>
                    Where you have previously received marketing communications
                    from us, then it will be necessary for our legitimate
                    business interests, namely to ensure you continue to receive
                    communications that you have previously agreed to receive In
                    all other cases, we will only do this if you give us your
                    consent
                  </li>
                </ul>
                <p className={para}>
                  We will only use your personal information for the purpose(s)
                  for which we collected it, unless we reasonably consider that
                  we need to use it for another reason and that reason is
                  compatible with the original purpose. If we need to use your
                  personal information for an unrelated purpose, we will notify
                  you and we will explain the legal basis which allows us to do
                  so. Please note that we may process your personal information
                  without your knowledge or consent, in compliance with the
                  above rules, where this is required or permitted by law.
                </p>
                <h2 className={paraHeader}>
                  What if you cannot or will not provide us with your personal
                  information?
                </h2>
                <p className={para}>
                  It is a contractual requirement for you to provide us with
                  certain information, namely your name, address, email address
                  and debit/credit card information. If you do not provide us
                  with that information, we will be unable to accept and process
                  your order and deliver the products to you.
                </p>
                <h2 className={paraHeader}>Cookie Policy</h2>
                <p className={para}>
                  Our website uses cookies to distinguish you from other users
                  of our website. This helps us to provide you with a good
                  experience when you browse our website and also allows us to
                  improve our website. A cookie is a small file of letters and
                  numbers that we store on your browser or the hard drive of
                  your computer if you agree. Cookies contain information that
                  is transferred to your computer's hard drive.
                </p>
                <h2 className={paraHeader}>We use the following cookies:</h2>
                <ul className={bulletPoints}>
                  <li>
                    <strong> Strictly necessary cookies.</strong>
                    These are cookies that are required for the operation of our
                    website. They include, for example, cookies that enable you
                    to log into secure areas of our website, use a shopping cart
                    or make use of e-billing services.
                  </li>
                  <li>
                    <strong> Analytical/performance cookies.</strong>
                    They allow us to recognise and count the number of visitors
                    and to see how visitors move around our website when they
                    are using it. This helps us to improve the way our website
                    works, for example, by ensuring that users are finding what
                    they are looking for easily.
                  </li>
                  <li>
                    <strong> Functionality cookies.</strong>
                    These are used to recognise you when you return to our
                    website. This enables us to personalise our content for you,
                    greet you by name and remember your preferences (for
                    example, your choice of language or region).
                  </li>
                  <li>
                    <strong> Targeting cookies.</strong>
                    These cookies record your visit to our website, the pages
                    you have visited and the links you have followed. We will
                    use this information to make our website and the advertising
                    displayed on it more relevant to your interests. We may also
                    share this information with third parties for this purpose.
                  </li>
                </ul>
                <p className={para}>
                  Please note that third parties (including, for example,
                  advertising networks and providers of external services like
                  web traffic analysis services) may also use cookies, over
                  which we have no control. These cookies are likely to be
                  analytical/performance cookies or targeting cookies. You block
                  cookies by activating the setting on your browser that allows
                  you to refuse the setting of all or some cookies. However, if
                  you use your browser settings to block all cookies (including
                  essential cookies) you may not be able to access all or parts
                  of our website. Except for essential cookies, all cookies will
                  expire after 30 days
                </p>
                <h2 className={paraHeader}>Disclosure of your information</h2>
                <p className={para}>
                  We may share your personal information with the parties set
                  out below:
                </p>
                <ul className={bulletPoints}>
                  <li>
                    Providers of IT and system administration services to our
                    business, (including mailchimp and other electronic
                    mailings), [Dropbox, One Drive, Google Drive, Adyen Payment
                    Processors, iCabbi, Korelogic and other online cloud
                    providers]
                  </li>
                  <li>
                    Our professional advisers (including solicitors, bankers,
                    auditors and insurers) who require reporting of processing
                    activities in certain circumstances,
                  </li>
                  <li>
                    HM Revenue & Customs, the Information Commissioner’s Office,
                    regulators and other authorities
                  </li>
                  <li>
                    Analytics and search engine providers that assist us in the
                    improvement and optimisation of our website, and
                  </li>
                  <li>
                    Third parties to whom we may choose to sell, transfer, or
                    merge parts of our business or our assets. Alternatively, we
                    may seek to acquire other businesses or merge with them. If
                    a change happens to our business, then the new owners may
                    use your personal information in the same way as set out in
                    this policy.
                  </li>
                </ul>
                <p className={para}>
                  We require all third parties to respect the security of your
                  personal information and to treat it in accordance with the
                  law. We do not allow our third-party service providers to use
                  your personal information for their own purposes and only
                  permit them to process your personal information for specified
                  purposes and in accordance with our instructions.
                </p>
                <h2 className={paraHeader}>
                  Where we store your personal information
                </h2>
                <p className={para}>
                  All information you provide to us is stored on our secure
                  servers in the United Kingdom. We will take all steps
                  reasonably necessary to ensure that your data is treated
                  securely, including taking the following safeguards:
                </p>
                <ul className={bulletPoints}>
                  <li>
                    <strong>PCI DSS standards.</strong>
                    We comply with the Payment Card Industry Data Security
                    Standards in relation to debit/credit card payments made on
                    our website.
                  </li>
                  <li>
                    <strong>Building entry controls.</strong>
                  </li>
                  <li>
                    <strong>Secure lockable desks and cupboards.</strong>
                    Desks and cupboards are kept locked when not in use if they
                    hold confidential information of any kind.
                  </li>
                  <li>
                    <strong>Methods of disposal. </strong>
                    Paper documents are disposed of by shredding in a manner
                    that ensures confidentiality.
                  </li>
                  <li>
                    <strong>Firewalls and Encryption.</strong>
                    We use up-to-date and industry-standard firewall and
                    encryption software technology.
                  </li>
                  <li>
                    <strong>Overseas transfers.</strong>
                    Whenever we transfer your personal information outside the
                    United Kingdom, we ensure a similar degree of protection is
                    afforded to it by ensuring that we apply appropriate
                    safeguards (either by transferring data only to recipients
                    in the European Union, to recipients in countries approved
                    by the European Commission, to recipients that are party to
                    the EU-US Privacy Shield, or by using specific contracts
                    approved by the European Commission).
                  </li>
                </ul>
                <p className={para}>
                  If you are concerned about the levels of data security in any
                  of those countries, please let us know and we will endeavour
                  to advise what steps will be taken to protect your data when
                  stored overseas. Unfortunately, the transmission of
                  information via the internet is not completely secure.
                  Although we will do our best to protect your personal
                  information, we cannot guarantee the security of your data
                  transmitted to our website; any transmission is at your own
                  risk. Once we have received your information, we will use
                  strict procedures and security features to try to prevent
                  unauthorised access.
                </p>
                <h2 className={paraHeader}>
                  How long we will store your personal information
                </h2>
                <p className={para}>
                  The length of time that we will store your data will depend on
                  the ‘legal basis’ for why we are using that data, as follows:
                </p>
                <ul className={bulletPoints}>
                  <li>
                    <strong>
                      Where we use/store your data because it is necessary for
                      the performance of the contract between you and us
                    </strong>
                    We will use/store your data for as long as it is necessary
                    for the performance of the contract between you and us
                  </li>
                  <li>
                    <strong>
                      Where we use/store your data because it is necessary for
                      us to comply with a legal obligation to which we are
                      subject
                    </strong>
                    We will use/store your data for as long as it is necessary
                    for us to comply with our legal obligations
                  </li>
                  <li>
                    <strong>
                      Where we use/store your data because it is necessary for
                      our legitimate business interests
                    </strong>
                    We will use/store your data until you ask us to stop.
                    However, if we can demonstrate the reason why we are
                    using/storing your data overrides your interests, rights and
                    freedoms, then we will continue to use and store your data
                    for as long as it is necessary for the performance of the
                    contract between you and us (or, if earlier, we no longer
                    have a legitimate interest in using/storing your data)
                  </li>
                  <li>
                    <strong>
                      Where we use/store your data because you have given us
                      your specific, informed and unambiguous consent
                    </strong>
                    We will use/store your data until you ask us to stop
                  </li>
                </ul>
                <p className={para}>
                  To determine the appropriate retention period for personal
                  information, we consider the amount, nature and sensitive of
                  the personal information, the potential risk of harm from
                  unauthorised use or disclosure of your personal information,
                  the purposes for which we process your personal information
                  and whether we can achieve those purposes through other means,
                  and the applicable legal requirements.
                </p>

                <h2 className={paraHeader}>Your Rights</h2>

                <p className={para}>
                  You have various legal rights in relation to the information
                  you give us, or which we collect about you, as follows:
                </p>

                <ul className={bulletPoints}>
                  <li>
                    You have a right to access the information we hold about you
                    free-of-charge, together with various information about why
                    and how we are using your information, to whom we may have
                    disclosed that information, from where we originally
                    obtained the information and for how long we will use your
                    information.
                  </li>
                  <li>
                    You have the right to ask us to rectify any information we
                    hold about you that is inaccurate or incomplete.
                  </li>
                  <li>
                    You have the right to ask us to erase the information we
                    hold about you (the ‘right to be forgotten’). Please note
                    that this right can only be exercised in certain
                    circumstances and, if you ask us to erase your information
                    and we are unable to do so, we will explain why not.
                  </li>
                  <li>
                    You have the right to ask us to stop using your information
                    where: (i) the information we hold about you is inaccurate;
                    (ii) we are unlawfully using your information; (iii) we no
                    longer need to use the information; or (iv) we do not have a
                    legitimate reason to use the information. Please note that
                    we may continue to store your information, or use your
                    information for the purpose of legal proceedings or for
                    protecting the rights of any other person.
                  </li>
                  <li>
                    You have the right to ask us to transmit the information we
                    hold about you to another person or company in a structured,
                    commonly-used and machine-readable format. Please note that
                    this right can only be exercised in certain circumstances
                    and, if you ask us to transmit your information and we are
                    unable to do so, we will explain why not.
                  </li>
                  <li>
                    Where we use/store your information because it is necessary
                    for our legitimate business interests, you have the right to
                    object to us using/storing your information. We will stop
                    using/storing your information unless we can demonstrate why
                    we believe we have a legitimate business interest which
                    overrides your interests, rights and freedoms.
                  </li>
                  <li>
                    Where we use/store your data because you have given us your
                    specific, informed and unambiguous consent, you have the
                    right to withdraw your consent at any time.
                  </li>
                  <li>
                    You have the right to object to us using/storing your
                    information for direct marketing purposes.
                  </li>
                </ul>

                <p className={para}>
                  If you wish to exercise any of your legal rights, please
                  contact our Customer Services Team by writing to the address
                  at the top of this policy, or by emailing us at
                  hello@air-pay.co.uk. You also have the right, at any time, to
                  lodge a complaint with the Information Commissioner’s Office
                  if you believe we are not complying with the laws and
                  regulations relating to the use/storage of the information you
                  give us, or that we collect about you.
                </p>

                <h2 className={paraHeader}>
                  Opting out of receiving marketing communications
                </h2>
                <p className={para}>
                  You can ask us to stop sending you marketing communications at
                  any time by contacting our Customer Services Team by writing
                  to the address at the top of this policy, or by emailing us at
                  hello@air-pay.co.uk.
                </p>

                <h2 className={paraHeader}>Automated decision-making</h2>
                <p className={para}>
                  We do not use automated decision-making processes.
                </p>

                <h2 className={paraHeader}>Third party links</h2>
                <p className={para}>
                  Our website may include links to third-party websites,
                  plug-ins and applications. Clicking on those links or enabling
                  those connections may allow third parties to collect or share
                  data about you. We do not control these third-party websites
                  and are not responsible for their privacy statements. When you
                  leave our website, we encourage you to read the privacy notice
                  of every website you visit.
                </p>

                <h2 className={paraHeader}>Changes to our policy</h2>

                <p className={para}>
                  Any changes we make to our policy in the future will be posted
                  on our website and, where appropriate, notified to you by
                  email, website & via social media. Please check our website
                  frequently to see any updates or changes to our policy.
                </p>

                <h2 className={paraHeader}>Contact</h2>
                <p className={para}>
                  Questions, comments and requests regarding this policy are
                  welcomed and should be addressed to our Customer Services Team
                  by writing to the address at the top of this policy, or by
                  emailing us at hello@air-pay.co.uk.
                </p>
              </div>
              <div className={actionBar}>
                <a href="#_" className={backLink} onClick={this.close}>
                  <ReactSVG className={linkIcon} src={leftArrow} />
                  Back
                </a>
              </div>
            </div>
          </CSSTransition>
        </div>
      </CSSTransition>
    )
  }
}

const PrivacyView = injectSheet(styles)(PrivacyViewEl)
export default PrivacyView
