const styles = {
  notFoundWrapper: {
    position: 'relative',
    zIndex: 1,
    padding: '20px',
    lineHeight: '32px',
  },
  header: {
    fontSize: '26px',
    fontWeight: 600,
    color: '#c4d5fc',
  },
  para: {
    fontSize: '16px',
    color: '#c4d5fc',
  },
  link: {
    color: '#fff',
    fontSize: '16px',
    paddingTop: '12px',
    fontWeight: '700',
    display: 'block',
  },
}

export default styles
