const styles = {
  padding: {
    padding: '20px',
  },
  viewWrapper: {
    zIndex: 100,
    position: 'relative',
  },
  title: {
    fontSize: '24px',
    padding: '13px 20px',
    color: '#fff',
  },
  listView: {
    padding: '0px 0px 0px 20px',
    backgroundColor: '#fff',
    height: 'calc(100vh - 165px)',
    borderRadius: '20px 20px 0 0',
    overflow: 'scroll',
  },
  Ul: {
    margin: 0,
    padding: 0,
  },
  Li: {
    padding: '16px 0px',
    clear: 'both',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #eaeaea',
  },
  date: {
    fontSize: '12px',
    color: '#6d6d6d',
    fontWeight: 500,
    display: 'block',
    marginTop: '5px',
  },
  meta: {
    display: 'block',
    color: '#292bbb',
  },
  cost: {
    fontSize: '22px',
    color: '#2d2d2d',
    display: 'block',
  },
  jid: {
    fontSize: '12px',
    color: '#2d2d2d',
    fontWeight: 500,
    display: 'block',
    marginTop: '5px',
  },
  left: {},
  right: {
    minWidth: '95px',
    textAlign: 'right',
    marginRight: '20px',
  },
  inlineLoading: {
    padding: '20px 0px',
    display: 'block',
    color: '#a9a9a9',
    fontStyle: 'italic',
  },
  status: {
    color: '#fff',
    padding: '4px 7px',
    display: 'inline-block',
    fontSize: '9px',
    textAlign: 'center',
    fontWeight: 600,
    borderRadius: '3px',
    marginTop: '6px',
  },
  paid: {
    backgroundColor: '#d4efe3',
    color: '#0d8050',
  },
  pending: {
    backgroundColor: '#efefef',
    color: '#999',
  },
  refunded: {
    backgroundColor: '#ffdfe0',
    color: 'red',
  },
  cancelled: {
    backgroundColor: 'red',
  },
}

export default styles
