import { gql } from 'apollo-boost'

export const journeysFromCode = gql`
  query journeysFromCode($journeysViewCode: String!) {
    journeysFromCode(journeysViewCode: $journeysViewCode) {
      id
      icabbiTripId
      status
      driver {
        fleet {
          name
        }
      }
      latestTransaction {
        id
        type
        paymentStatus
        grandTotal
        createdAt
        adyenLastSentForSettleAt
      }
    }
  }
`
