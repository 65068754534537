import { gql } from 'apollo-boost'

export const lastSeen = gql`
  mutation createLastSeen(
    $firstSeenAt: String
    $lastSeenAt: String
    $journeyId: String
  ) {
    createLastSeen(
      firstSeenAt: $firstSeenAt
      lastSeenAt: $lastSeenAt
      journeyId: $journeyId
    ) {
      id
      status
    }
  }
`
