import ApolloClient from 'apollo-boost'

export const getEndpoint = () => {
  let url = 'https://staging.air-pay.co.uk/'
  switch (process.env.NODE_ENV) {
    case 'production':
      url = 'https://go.air-pay.co.uk/'
      break
    case 'development':
      url = 'http://localhost:3010/'
      break
  }
  return url
}

const client = new ApolloClient({
  uri: getEndpoint() + 'gql',
})

export default client
