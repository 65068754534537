import React, { Fragment } from 'react'
import injectSheet from 'react-jss'
import styles from './infoViewStyles'
import ReactSVG from 'react-svg'
import rightArrow from '../../assets/images/arrow-right.svg'

class InfoViewEl extends React.Component {
  constructor(props) {
    super(props)
    this.openTerms = this.openTerms.bind(this)
    this.openPrivacy = this.openPrivacy.bind(this)
  }
  openTerms(e) {
    e.preventDefault(e)
    this.props.openTerms(true)
  }

  openPrivacy(e) {
    e.preventDefault(e)
    this.props.openPrivacy(true)
  }
  render() {
    const {
      subHeader,
      para,
      linksBlock,
      rowLink,
      rowIcon,
      closeLink,
    } = this.props.classes

    const { fleetName, partnerCharge } = this.props
    return (
      <Fragment>
        <h6 className={subHeader}>Terms &amp; Conditions</h6>
        <p className={para}>
          Airpay is a secure, GDPR and PCI compliant payment service working on
          behalf of {fleetName}. Payments through Airpay are subject to a{' '}
          {partnerCharge}p service charge.
        </p>
        <div className={linksBlock}>
          <a href="#_" className={rowLink} onClick={this.openTerms}>
            Full Terms and Conditions
            <ReactSVG className={rowIcon} src={rightArrow} />
          </a>
          <a href="#_" className={rowLink} onClick={this.openPrivacy}>
            Privacy Policy
            <ReactSVG className={rowIcon} src={rightArrow} />
          </a>
        </div>
        <a
          id="close-on-click-2"
          className={closeLink}
          href="#_"
          onClick={e => this.props.close(e)}
        >
          Close
        </a>
      </Fragment>
    )
  }
}

const InfoView = injectSheet(styles)(InfoViewEl)
export default InfoView
