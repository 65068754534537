const formStyles = {
  form: {
    margin: 'auto',
  },
  label: {
    color: '#2386f2',
    fontSize: '13px',
    fontWeight: 600,
  },
  requiredSpan: {
    color: 'red',
    marginLeft: '4px',
  },
  field: {
    position: 'relative',
    marginBottom: '30px',
    '&.narrow': {
      width: '30%',
      position: 'relative',
      display: 'inline-block',
      paddingRight: '5%',
      '&:last-child': {
        paddingRight: 0,
      },
    },
    '&.last': {
      paddingRight: 0,
    },
  },
  input: {
    width: '100%',
    display: 'block',
    fontSize: '25px',
    padding: '8px 0px 8px 0px',
    border: 0,
    fontWeight: 300,
    borderBottom: '1px solid #d7dce2',
    borderRadius: 0,
    '&:focus': {
      outline: 0,
      borderBottomColor: ' #55a0f1',
    },
    '&.error': {
      borderBottomColor: 'red',
    },
    '&::placeholder': {
      color: '#d7dce2',
    },
  },
  errorSpan: {
    color: 'red',
    position: 'absolute',
    right: 0,
    top: '2px',
    margin: 0,
    fontSize: '11px',
    fontWeight: 600,
  },
  mainBtn: {
    display: 'block',
    width: '100%',
    border: 0,
    backgroundColor: '#157efc',
    padding: '15px',
    fontSize: '17px',
    fontWeight: 700,
    color: '#fff',
    borderRadius: '4px',
    '-webkit-appearance': 'none',
    marginTop: '45px',
    '&:focus': {
      outline: 0,
    },
    '&:hover': {
      backgroundColor: '#157efc',
      cursor: 'pointer',
    },
  },
  secondaryBtn: {
    display: 'block',
    width: '100%',
    border: 0,
    backgroundColor: 'transparent',
    padding: '15px',
    fontSize: '17px',
    fontWeight: 500,
    textTransform: 'uppercase',
    color: '#31d099',
    borderRadius: '50px',
    border: '2px solid #31d099',
    '&:focus': {
      outline: 0,
    },
    '&:hover': {
      backgroundColor: '#52dcad',
      cursor: 'pointer',
    },
  },
}

export default formStyles
