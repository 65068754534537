import { gql } from 'apollo-boost'

export const journeyById = shortId => gql`
  {
    journeys(where: { shortId: "${shortId}" }) {
      id
      shortId
      status
      estimatedCost
      dispatcherEstimatedTripCost
      dispatcherTripCost
      estimateFromAddress
      fixedCost
      driver {
        name
        fleet {
          name
          id
          key
          fleetCharge
          stripePublicAPIKey
          absorbPlatformCharge
          excludePlatformCharge
          overrideDefaultPlatformCharge
          overrideDefaultPlatformChargeValue
          enableSynchronousAuth
          partner {
              serviceCharge
          }
        }
      }
      customer {
        id
        name
      }
      transactions {
        id
        type
        status
        grandTotal
        gatewayStatus
      }
      icabbiTripId
    }
  }
`
