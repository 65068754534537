import closeIcon from '../../assets/images/e-remove.svg'
import { sharedContentStyles } from '../overlayView/overlayViewStyles'

const styles = {
  subHeader: { ...sharedContentStyles.subHeader },
  para: { ...sharedContentStyles.para },
  linksBlock: {
    marginTop: '10px',
  },
  rowLink: {
    color: '#1f51e0',
    display: 'block',
    padding: '15px 0px',
    fontSize: '16px',
    borderBottom: '1px solid #eaeaea',
    fontWeight: 500,
    position: 'relative',
  },
  rowIcon: {
    position: 'absolute',
    right: 0,
    top: '13px',
    '& svg': {
      height: '18px',
    },
  },

  closeLink: {
    color: '#7d7d7d',
    padding: '20px 22px',
    display: 'inline-block',
    backgroundImage: `url(${closeIcon})`,
    backgroundPosition: 'left center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '14px',
    fontSize: '17px',
    fontWeight: 600,
    position: 'absolute',
    bottom: 0,
    left: '15px',
  },
}

export default styles
