const styles = {
  logo: {
    width: '70px',
    padding: '10px 0px',
    zIndex: 1,
    position: 'relative',
    transform: 'scale(0.4)',
  },
  trackLink: {
    position: 'fixed',
    right: '15px',
    top: '15px',
    zIndex: 10,
    backgroundColor: '#fff',
    borderRadius: '20px',
    padding: '10px 20px',
    fontSize: '15px',
    color: '#1f57e6',
    boxShadow: '0px 7px 20px #1a5ed2',
  },
}

export default styles
