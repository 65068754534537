import backgroundMap from '../assets/images/bg-map.png'
const styles = {
  appWrapper: {
    backgroundColor: '#092332',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage: `url(${backgroundMap})`,
    minHeight: '100%',
    minWidth: '100%',
    maxWidth: '100%',
    /* Set up proportionate scaling */
    width: '100%',
    height: 'auto',

    /* Set up positioning */
    top: '0',
    left: '0',

    position: 'absolute',
    width: '100%',
    minHeight: '100%',
    overflow: 'scroll',
  },
}

export default styles
