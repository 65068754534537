import React from 'react'
import injectSheet from 'react-jss'
import styles from './passengerNoticeViewStyles'

class PassengerNoticeViewEl extends React.Component {
  constructor(props) {
    super(props)
    this.openTerms = this.openTerms.bind(this)
    this.openPrivacy = this.openPrivacy.bind(this)
  }
  openTerms(e) {
    e.preventDefault(e)
    this.props.openTerms(true)
  }

  openPrivacy(e) {
    e.preventDefault(e)
    this.props.openPrivacy(true)
  }
  render() {
    const {
      subHeader,
      para,
      button,
      cabMeterSVG,
      subTextSpan,
      estimatedCostNotice,
      subtitle,
      logo,
    } = this.props.classes

    const {
      partnerCharge,
      estimatedJourneyCharge,
      estimateFromAddress,
      fixedCost,
    } = this.props
    return (
      <>
        {estimateFromAddress && (
          <span className={estimatedCostNotice}>{estimatedJourneyCharge}</span>
        )}
        {estimateFromAddress && (
          <span className={subtitle}>
            {fixedCost ? 'Final Fare' : 'Estimated Fare'}
          </span>
        )}
        <p className={para}>
          {fixedCost
            ? 'This fare will be charged to your card immediately.'
            : 'The estimated fare will be authorised on your card immediately, at the end of this journey we will charge the fare on the drivers meter to your card.'}
          {partnerCharge > 0 && (
            <span className={subTextSpan}>
              {`${
                fixedCost ? 'This includes a' : '+'
              } ${partnerCharge}p Airpay Charge`}
            </span>
          )}
        </p>
        <a
          id="close-on-click-4"
          className={button}
          href="#_"
          onClick={e => this.props.close(e)}
        >
          OK, got it!
        </a>
      </>
    )
  }
}

const PassengerNoticeView = injectSheet(styles)(PassengerNoticeViewEl)
export default PassengerNoticeView
